// 导入 IntersectionObserver polyfill
import 'intersection-observer';

export default {
  inserted(el) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          el.classList.add('visible');
          observer.unobserve(entry.target); // 只触发一次
        }
      });
    }, {
      threshold: 0.1 // 10% 可见时触发
    });

    observer.observe(el);
  }
};
