import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  // {
  //   path: '/solution',
  //   name: 'solution',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Solution.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/charge',
  //   name: 'charge',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Charge.vue')
  // },



  // { 
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
