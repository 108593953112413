<template>
  <div class="swiper-slide">
    <div class="footer">
      <!-- 联系我们 -->
      <div class="contact">
        <div class="title">联系我们</div>
        <div class="subtitle">CONTACT US</div>
      </div>
      <!-- 联系方式 -->
      <div class="ContactWay">
        <ul>
          <li>
            <div class="left">
              <img src="../assets/footer/邮箱.png" alt />
            </div>
            <div class="right">
              <div>EMAIL</div>
              <div>13332827119@189.cn</div>
            </div>
          </li>
          <li>
            <div class="left">
              <img src="../assets/footer/位置.png" alt />
            </div>
            <div class="right">
              <div>ADDRESS</div>
              <div>广东省深圳市南山区西丽街道新围社区新围旺棠工业区12栋A601</div>
            </div>
          </li>
          <li>
            <div class="left">
              <img src="../assets/footer/电话.png" alt />
            </div>
            <div class="right">
              <div>PHONE</div>
              <div>+86 13332827119</div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 公司名称 -->
      <div class="CropName">
        <div class="line_one">
          <span class="name">深圳新能智慧充电科技有限公司</span>
          <span class="num">备案号：粤ICP备18021708号-1</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.swiper-slide {
  border-top: 1px solid #e5e5e5;
  // margin: 30px 100px;
  .footer {
    padding: 30px 100px;
  }
  .contact {
    // text-align: center;
    .title {
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      letter-spacing: 10px;
    }
    .subtitle {
      letter-spacing: 2px;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
    }
  }
  .ContactWay {
    margin: 20px 0;
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      li {
        width: 32%;
        display: flex;
        align-items: center;
        .left {
          width: 70px;
          height: 70px;
          border: 1px solid #7ac9ff;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center; /* 水平居中 */
          align-items: center; /* 垂直居中 */

          img {
            width: 50px;
            vertical-align: middle;
          }
        }
        .right {
          color: #686868;
          margin-left: 20px;
          div:first-child {
            font-size: 20px;
            letter-spacing: 2px;
            height: 30px;
          }
          // height: 30px;
          // line-height: 30px;
        }
      }
    }
  }
  .CropName {
    text-align: center;
    .num {
      margin-left: 20px;
      color: #b6b8bb;
    }
  }
}
@media screen and (max-width: 768px) {
  .swiper-slide {
    border-top: 1px solid #e5e5e5;
    .footer {
      padding: 2% 10px;
    }
    .contact {
      // text-align: center;
      padding: 0 30px;
      .title {
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        letter-spacing: 5px;
      }
      .subtitle {
        letter-spacing: 2px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
      }
    }
    .ContactWay {
      margin: 20px 0;
      padding: 0 20px;
      // text-align: center;
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .left {
            width: 50px;
            height: 50px;
            border: 1px solid #7ac9ff;
            border-radius: 50%;
            text-align: center;
            display: flex;
            justify-content: center; /* 水平居中 */
            align-items: center; /* 垂直居中 */

            img {
              width: 35px;
              vertical-align: middle;
            }
          }
          .right {
            width: calc(100% - 50px); /* 右侧内容固定宽度 */
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #686868;
            margin-left: 10px;
            div:nth-child(1) {
              font-size: 14px;
              letter-spacing: 2px;
            }
            div:nth-child(2) {
              font-size: 12px;
            }
            // height: 30px;
            // line-height: 30px;
          }
        }
      }
    }
    .CropName {
      text-align: center;
      .line_one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
      .num {
        margin-left: 20px;
        color: #b6b8bb;
        font-size: 14px;
      }
    }
  }
}
</style>