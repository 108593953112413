<template>
  <div>
    <!-- PC头部  -->
    <div>
      <!-- PC -->
      <div class="PC">
        <div class="Header isTop">
          <a href="/" class="nuxt-link-active">
            <img src="../assets/logo白.png" class="logo" />
          </a>
          <!-- <ul class="navHeader el-menu" style="background-color: transparent;">
            <li class="el-menu-item">
              <div>
                <span>解决方案</span>
              </div>
            </li>
            <li class="el-menu-item">
              <div>
                <span>关于新能慧充</span>
              </div>
            </li>
            <li class="el-menu-item">
              <div>
                <span>有序充电</span>
              </div>
            </li>
          </ul>-->
        </div>
      </div>
      <!-- 手机端 -->
      <div class="Mobile">
        <div class="MobileHeader isTop">
          <a href="/" class="nuxt-link-active">
            <img src="../assets/logo.png" class="logo" />
          </a>
          <div class="right">
            <div class="Global">
              <img
                ref="helloWorld1"
                src="https://resource.teld.cn/teldimage/107/hello-world.png"
                class="cn_change"
              />
              <img
                ref="helloWorld2"
                src="https://resource.teld.cn/teldimage/107/hello-world2.png"
                class="cn_change showImg"
              />
              <span class="language">EN</span>
            </div>
            <div class="part">手机专区</div>
            <!-- <div class="icon" @click="LoadMore">
              <img src="../assets/more.png" class="moreIcon" v-show="!loadMoreShow" alt />
              <img src="../assets/close.png" class="closeIcon" v-show="loadMoreShow" alt />
            </div>-->
          </div>
        </div>

        <!-- <div class="MobileMenu" :class="{ active: loadMoreShow }">
          <el-menu
            default-active="0"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item index="1">
              <span slot="title">解决方案</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">有序充电</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">关于新能慧充</span>
            </el-menu-item>
          </el-menu>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 手机端显示
      loadMoreShow: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    LoadMore() {
      console.log("点击了");
      this.loadMoreShow = !this.loadMoreShow;
    },
    handleOpen() {},
    handleClose() {},
    goToSolution() {
      this.$router.push("/solution");
    },
    goToAbout() {
      this.$router.push("/about");
    },
    goToCharge() {
      this.$router.push("/charge");
    },
    handleScroll() {
      // 处理滚动事件的逻辑
      this.loadMoreShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.isTop {
  background: rgba(16, 22, 27, 0.5);
  transition-duration: 1s;
}
.Header {
  display: flex;
  position: fixed;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  z-index: 20;

  .nuxt-link-active {
    list-style: none;
    text-decoration: none;
    .logo {
      cursor: pointer;
      height: 42px;
      margin-top: 10px;
      margin-left: 40px;
    }
  }

  .el-menu {
    display: flex;
    list-style: none;
    position: relative;
    // background-color: #000 !important;
    margin-right: 3% !important;
    border-right: 1px solid transparent;
    // min-width: 650px;

    .el-menu-item {
      font-size: 16px;
      font-weight: 900;
      color: #fff;
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      cursor: pointer;
      position: relative;
      border-bottom: 2px solid transparent;
      border-bottom-color: transparent;
      background-color: transparent;
      box-sizing: border-box;
      white-space: nowrap;

      a {
        color: inherit !important; /* 继承父元素的颜色 */
        text-decoration: none !important; /* 去除下划线 */
      }

      .Global {
        display: flex;
        align-items: center;
        .cn_change {
          width: 20px;
          height: 20px;
          margin-right: 5px;

          &.showImg {
            display: none;
          }
        }
      }
    }

    .el-menu-item:hover {
      color: rgb(122, 201, 255); /* 绿色 */

      .Global {
        display: flex;
        align-items: center;
        .cn_change {
          width: 20px;
          height: 20px;
          margin-right: 5px;

          &.showImg {
            display: block;
          }
          &.hideImg {
            display: none;
          }
        }
      }
    }
  }
}
.MobileHeader {
  display: none;
}
.MobileMenu {
  display: none;
}
@media screen and (max-width: 768px) {
  .Header {
    display: none;
  }
  .drop-down,
  .drop-down-menu,
  .horizontalMenu,
  .el-menu--horizontal {
    display: none;
  }
  .MobileHeader {
    display: block;
    display: flex;
    justify-content: space-between;
    .nuxt-link-active {
      list-style: none;
      text-decoration: none;
      .logo {
        cursor: pointer;
        height: 42px;
        margin-top: 10px;
        margin-left: 40px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .Global {
        display: flex;
        align-items: center;
        padding: 0 10px;
        .cn_change {
          width: 25px;
        }
        .showImg {
          display: none;
        }
        span {
          color: #fff;
        }
      }
      .part {
        color: #fff;
        border: 1px solid #fff;
        padding: 3px 10px;
        border-radius: 10px;
        margin-right: 10px;
      }
      .icon {
        padding: 0 10px;
        display: flex;
        .moreIcon {
          width: 30px;
        }
        .closeIcon {
          width: 25px;
        }
      }
    }
  }
  .MobileMenu {
    display: none;
  }
  .active {
    display: block;
  }
}

.horizontalMenu .el-menu--horizontal > .el-menu-item {
  width: 220px;
}

.drop-down .el-menu--horizontal > .el-menu-item {
  float: left;
}
.drop-down {
  display: flex;
  position: fixed;
  width: 100%;
  padding-top: 60px;
  z-index: 15;

  .drop-down-menu {
    margin-right: 7%;
    margin-left: 13%;
    margin-bottom: 2%;
  }
  .el-menu {
    border-right: none;
    list-style: none;
    position: relative;
    padding-left: 0;

    .el-menu-item {
      font-size: 16px;
      font-weight: 900;
      user-select: none;
      height: 60px;
      line-height: 60px;
      color: #fff;

      .drop-down-menu-item {
        .showCircle {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #7ac9ff;
          margin-right: 10px;
        }
        .hideCircle {
          opacity: 0;
        }
        .drop-down-menu-item-text {
          pointer-events: auto;
          text-decoration: none;
          cursor: pointer;
          color: #fff;
        }
      }
    }
    .drop-down-is-active {
      color: #7ac9ff !important;
    }
  }

  .categories {
    display: none;

    .categorie-menu {
      height: 60px;
      line-height: 60px;

      span {
        cursor: pointer;

        .hideCircle {
          opacity: 0;
        }
        .showCircle {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #7ac9ff;
          margin-right: 10px;
        }
        .drop-down-menu-item-text {
          pointer-events: auto;
          text-decoration: none;
          cursor: pointer;
          color: #fff;
        }
      }
    }
    .drop-down-is-active {
      color: #7ac9ff !important;
    }

    &.show {
      display: block;
    }
  }
}

.horizontalMenu {
  .el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu {
    width: 500px;
    margin-bottom: 2%;
  }
}

.hideMenu {
  max-height: 0;
  overflow: hidden;
}

.showMenu {
  max-height: 480px;
  overflow: hidden;
  transition: max-height 0.5s;
  background: rgba(0, 42, 71, 0.5) !important;
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
}
</style>