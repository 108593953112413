<template>
  <div>
    <!-- PC端显示 -->
    <div class="PC_Show">官网正在建设中，敬请期待，我们将为您带来更好的服务和体验~</div>
  </div>
</template>
<script>
import "swiper/css/swiper.min.css";
import ScrollReveal from "../directives/ScrollReveal";

export default {
  directives: {
    ScrollReveal
  },
  props: {
    title: {
      //模块标题
      type: String,
      default: ""
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      videos: [
        {
          poster: require("@/assets/homeImg/新能充电.jpg")
        },
        {
          poster: require("@/assets/homeImg/有序充电.jpg")
        },
        {
          poster: require("@/assets/homeImg/34.jpg")
        }
      ],
      swiper: null
    };
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    toDestination() {
      console.log("点击了toDestination");
      const destination = document.getElementById("Destination");
      document.documentElement.scrollTop = destination.offsetTop;
    },
    toOrderCharging() {
      console.log("点击了toOrderCharging");
      const orderCharging = document.getElementById("Order-Charging");
      document.documentElement.scrollTop = orderCharging.offsetTop;
    }
  }
};
</script>
<style lang="less" scoped>
.PC_Show {
  overflow: hidden;
  font-size: 50px;
  .swiper-slide {
    width: 100%;
    overflow: hidden;
    height: 100vh;

    .slide-wrap {
      position: relative;
      height: 100%;
      background-size: cover;

      .content {
        position: relative;
        height: 100%;
        background-size: cover;
        background-size: 100% 100%;
      }
    }
  }
  // 第一部分
  .show-imgs-container {
    height: 56.25vw;

    .el-carousel {
      width: 100%;
      height: 56.25vw;
    }

    .el-carousel__item {
      width: 100%;
      height: 56.25vw;
      img {
        width: 100%;
        height: 56.25vw;
        position: fixed;
        display: inline-block;
        vertical-align: baseline;
        object-fit: fill; //消除留白
      }
    }

    ::v-deep .el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.11);
      color: #fff;
      position: absolute;
      // top: 150%;
      z-index: 10;
      // transform: translateY(-100%);
      text-align: center;
      font-size: 12px;
    }
  }
  // 第二部分 公司简介
  .slid2 {
    padding: 0 150px;
    margin-bottom: 80px;
    opacity: 0;
    transition: opacity 2s ease;
    .head {
      display: flex;
      align-content: center;
      justify-content: space-between;
      height: 124px;
      .left {
        display: flex;
        align-items: center;
        .logo {
          height: 80px;
        }
        .title {
          width: 480px;
          line-height: 124px;
          text-indent: 50px;
          font-weight: 500;
          font-size: 28px;
        }
      }
      .right {
        ul {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          align-items: center;
          li {
            width: 100px;
            height: 124px;
            line-height: 124px;
            cursor: pointer;
          }
        }
      }
    }
    .content {
      line-height: 35px;
      font-size: 20px;
      color: #333333;
    }
  }
  .slid2.visible {
    opacity: 1;
  }

  @media (max-width: 1800px) {
    .slid2 {
      .content {
        line-height: 30px;
      }
    }
  }
  @media (max-width: 1600px) {
    .slid2 {
      .content {
        line-height: 25px;
      }
    }
  }
  @media (max-width: 1200px) {
    .slid2 {
      .head {
        height: 104px;
        .left {
          .logo {
            height: 60px;
          }
          .title {
            width: 380px;
            line-height: 104px;
            text-indent: 20px;
            font-weight: 500;
            font-size: 24px;
          }
        }
      }
      .content {
        line-height: 25px;
        font-size: 18px;
        color: #333333;
      }
    }
  }
  @media (max-width: 768px) {
    .slid2 {
      padding: 0 30px;
      margin-bottom: 20px;
      .head {
        display: flex;
        align-content: center;
        justify-content: space-between;
        height: 70px;
        .left {
          display: flex;
          align-items: center;
          .logo {
            height: 30px;
          }
          .title {
            width: 300px;
            line-height: 70px;
            text-indent: 10px;
            font-weight: 500;
            font-size: 18px;
          }
        }
        .right {
          display: none;
        }
      }
      .content {
        line-height: 20px;
        font-size: 12px;
        color: #333333;
      }
    }
  }

  // 目的地充电
  .slide3 {
    padding: 0 150px;
    .de-title {
      margin-top: 16px;
      line-height: 35px;
      font-weight: 400;
      font-style: normal;
      font-size: 28px;
      letter-spacing: normal;
      color: #333333;
      text-align: left;
      text-transform: none;
    }
    .de-subTitle {
      margin-top: 16px;
      line-height: 35px;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      color: #333333;
      text-align: left;
      text-transform: none;
      padding-top: 0;
      font-size: 20px;
      margin-bottom: 16px;
    }
    .De-content {
      .member-wrap {
        width: 100%;
        display: flex;
        .de-member:nth-child(odd) {
          margin-right: 4%;
        }
        .de-member {
          // float: left;

          width: 48%;
          margin-bottom: 4%;
          overflow: hidden;
          .de-img {
            width: 100%;
            padding-bottom: 66%;
            height: 0;
          }
          .de-img1 {
            background: url(../assets/pile/6.jpg);
            background-size: cover;
          }
          .de-img2 {
            background: url(../assets/pile/add.jpg);
            background-size: cover;
          }
          .de-mem-sub {
            margin-top: 15px;
            .main-title {
              color: #069559;
              font-size: 21px;
              font-weight: 600;
            }
          }
        }
        .de-member:hover {
          transition: transform 0.3s ease;
          transform: scale(1.05);
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .slide3 {
      padding: 0 150px;
      .de-title {
        margin-top: 16px;
        line-height: 35px;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
      }
      .de-subTitle {
        margin-top: 16px;
        line-height: 35px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 16px;
      }
      .De-content {
        .member-wrap {
          width: 100%;
          display: flex;
          .de-member:nth-child(odd) {
            margin-right: 4%;
          }
          .de-member {
            width: 48%;
            margin-bottom: 4%;
            .de-img {
              width: 100%;
              padding-bottom: 66%;
              height: 0;
            }
            .de-img1 {
              background: url(../assets/pile/6.jpg);
              background-size: cover;
            }
            .de-img2 {
              background: url(../assets/pile/add.jpg);
              background-size: cover;
            }
            .de-mem-sub {
              margin-top: 15px;
              .main-title {
                color: #069559;
                font-size: 18px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .slide3 {
      padding: 0 30px;
      .de-title {
        margin-top: 10px;
        line-height: 25px;
        font-size: 22px;
      }
      .de-subTitle {
        margin-top: 10px;
        line-height: 20px;
        padding-top: 0;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .De-content {
        .member-wrap {
          width: 100%;
          display: flex;
          .de-member:nth-child(odd) {
            margin-right: 4%;
          }
          .de-member {
            .de-mem-sub {
              margin-top: 15px;
              font-size: 12px;
              .main-title {
                color: #069559;
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  // 有序充电
  .charge {
    padding: 0 150px;
    .partTwo {
      height: auto;
      .Title {
        margin-top: 16px;
        line-height: 35px;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
      }
      .subTitle {
        margin-top: 16px;
        line-height: 35px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        color: #333333;
        text-align: left;
        text-transform: none;
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 16px;
      }
      .content {
        display: flex;
        align-items: center;
        margin: 30px 0;
        .contentLeft {
          flex: 1;
          height: 100%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            object-position: top;
            transition: transform 0.3s ease;
          }
        }
        .contentLeft:hover img {
          transform: scale(1.1);
        }
        .contentRight {
          flex: 1;
          background-color: #f6f6f6;
          .advantage {
            border-bottom: 1px solid #ccc;
            margin: 10px;
            padding: 0 10px 10px 10px;
            .title {
              margin-bottom: 5px;
              font-size: 18px;
            }
            ul li {
              list-style: none;
            }
            li {
              // color: #a8a6a6;
              margin-bottom: 5px;
              font-size: 16px;
              color: #797575;
              line-height: 35px;
            }
          }
          .feature {
            margin: 10px;
            padding: 0 10px 10px 10px;
            .title {
              font-size: 18px;
              margin-bottom: 5px;
            }
            ul li {
              list-style: none;
            }
            li {
              margin-bottom: 5px;
              font-size: 16px;
              color: #797575;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1700px) {
    .charge {
      .partTwo {
        height: auto;
        .content {
          .contentLeft {
            flex: 1;
            height: 100%;
            img {
              width: 100%;
              height: 300px;
              object-fit: cover;
              object-position: top;
            }
          }
          .contentRight {
            flex: 1;
            .advantage {
              ul li {
                list-style: none;
              }
              li {
                line-height: 25px;
              }
            }
            .feature {
              li {
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .charge {
      padding: 0 150px;
      .partTwo {
        height: auto;
        .content {
          display: flex;
          align-items: center;
          margin: 30px 0;
          .contentLeft {
            flex: 1;
            height: 100%;
            img {
              width: 100%;
              height: 260px;
              object-fit: cover;
              object-position: top;
            }
          }

          .contentRight {
            flex: 1;
            background-color: #f6f6f6;
            .advantage {
              li {
                // color: #a8a6a6;
                margin-bottom: 5px;
                font-size: 16px;
                color: #797575;
                line-height: 20px;
              }
            }
            .feature {
              li {
                margin-bottom: 5px;
                font-size: 16px;
                color: #797575;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .charge {
      padding: 0 30px;
      .partTwo {
        .Title {
          margin-top: 10px;
          line-height: 20px;
          font-size: 22px;
        }
        .subTitle {
          margin-top: 10px;
          line-height: 10px;
          padding-top: 0;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .content {
          display: block;
          align-items: center;
          margin: 15px 0;
          .contentLeft {
            height: 100%;
            img {
              width: 100%;
              height: 350px;
              object-fit: cover;
              object-position: top;
            }
          }
          .contentRight {
            background-color: #f6f6f6;
            .advantage {
              border-bottom: 1px solid #ccc;
              margin: 10px;
              padding: 0 10px 10px 10px;
              .title {
                margin-bottom: 5px;
                font-size: 14px;
              }
              li {
                // color: #a8a6a6;
                margin-bottom: 5px;
                font-size: 12px;
                color: #797575;
                line-height: 20px;
              }
            }
            .feature {
              margin: 10px;
              padding: 0 10px 10px 10px;
              .title {
                font-size: 14px;
                margin-bottom: 5px;
              }
              ul li {
                list-style: none;
              }
              li {
                margin-bottom: 5px;
                font-size: 12px;
                color: #797575;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  // 侧边部分
  .aside {
    z-index: 2;
    position: fixed;
    right: 50px;
    bottom: 45px;

    li {
      .bg-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: rgba(0, 83, 131, 0.25);
        margin-bottom: 17px;
        cursor: pointer;
        .top-img {
          width: 65%;
        }
        .aside-icon {
          width: 50%;
        }
        .left-img-wrap {
          position: absolute;
          display: none;
          left: -125px;
          top: -50%;
          width: 140px;
          height: 110px;
          .left-img {
            width: 110px;
            height: 110px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .aside {
      display: none;
    }
  }

  li,
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>